var assert = require('assert')

module.exports = nanolocation

function nanolocation () {
  assert.notEqual(typeof window, 'undefined', 'nanolocation: expected window to exist')
  var pathname = window.location.pathname.replace(/\/$/, '')
  var hash = window.location.hash.replace(/^#/, '/')
  return pathname + hash
}
